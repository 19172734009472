<template>
    <div class="flex-wrap"
         :class="classType">
        <ul class="time-list"
            @click="showRecentMonth = false">
            <li v-for="item in timeList"
                :key="item.type"
                class="time-item"
                :class="current == item.type ? 'current' : ''"
                @click="timeChange($event,item)">
                <span>{{item.label}}</span>
            </li>
        </ul>
        <ul class="recent-month"
            v-if="showRecentMonth">
            <li v-for="item in recentMonthList"
                :key="item"
                @click="recentTimeChange(item)">
                {{item}}月
            </li>
        </ul>
    </div>
</template>

<script>
	import util from '@/utils/util'

	export default {
		name:"time-select",
		props:{
			classType:String,
		},
		data(){
			return {
				timeList:[
					{type:1,label:'今日'},
					{type:2,label:'昨日'},
					{type:3,label:'近7日'},
					{type:4,label:'本月'},
					{type:5,label:'近三月'},
				],
				current:1,
				showRecentMonth:false,
			}
        },
        computed:{
			recentMonthList(){
				let foreignMonth=new Date().getMonth()
				let chinaMonth=[1,2,3,4,5,6,7,8,9,10,11,12]
				return[chinaMonth.slice(foreignMonth)[0],chinaMonth.slice(foreignMonth-1)[0],chinaMonth.slice(foreignMonth-2)[0]]
			}
		},
		methods:{
			/**
			 * 时间选择
			 */
			timeChange(e,item){
				let type = item.type
				let params = util.getTimeParam(type)
				if(type !== 5){
					this.current = type
					this.timeList[4].label = '近三月'
					this.$emit('timeChange',params)
				}else{
					e.stopPropagation()
					this.showRecentMonth = true
				}
			},
			/**
			 * 近三月时间选择
			 */
			recentTimeChange(item){
				let params = util.getTimeParam(5,item)
        if(this.recentMonthList[0]<3&&(item===11||item===12)){
          params.startTime=(new Date().getFullYear()-1)+params.startTime.slice(4)
          params.endTime=(new Date().getFullYear()-1)+params.endTime.slice(4)
        }
        console.log(params)
				this.current = 5
				this.timeList[4].label = item + '月'
				this.$emit('timeChange',params)
				this.showRecentMonth = false
			},
		},
		created(){
			this.$emit('timeChange',util.getTimeParam(1))
		},
	}
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss" scoped>
    @import "~@/assets/style/public.scss";

    .flex-wrap{
        background:#FFFFFF;
        box-shadow:2px 2px 4px rgba(0,0,0,0.2);

        .time-list{
            @extend %flex-center-row;
            height:128px;
            line-height:128px;
            background:#f5f5f7;
            padding:0 20px;
            li{
                flex:1;
                text-align:center;
            }
            span{
                display:inline-block;
                line-height:60px;
                height:60px;
                width:132px;
                font-size:28px;
                color:rgb(102,102,102);
                text-align:center;
                background:rgb(229,229,229);
                border-radius:30px;
                position:relative;
            }

            .current{
                span{
                    color:$high-light-color;
                    background:#fff;
                    border:1px solid $high-light-color;
                }
            }
        }

        .recent-month{
            position:absolute;
            right:10px;
            top:300px;
            background:#191919;
            border-radius:10px;
            color:#FFFFFF;
            z-index:2;

            li{
                border-bottom:1px solid #4d555e;
                width:135px;
                height:66px;
                line-height:65px;
                font-size:26px;
                text-align:center;
            }

            li:last-child{
                border:0;
            }
        }

        .recent-month:before{
            position:absolute;
            left:50%;
            top:-16px;
            transform:translateX(-50%);
            content:'';
            width: 0;
            height: 0;
            border-width: 0 14px 20px;
            border-style: solid;
            border-color: transparent transparent #191919;
        }
    }

    .tech-ranking{
        background:transparent;
        box-shadow:none;
        position:relative;
        .time-list{
            background:transparent;
            color:#FFFFFF;

            .current{
                span:after{
                    content:'';
                    position:absolute;
                    bottom:0;
                    left:0;
                    right:0;
                    height:6px;
                    background:#FFFFFF;
                }
            }
        }
    }

</style>